import Vue from 'vue'
import Router from 'vue-router'
// import NotFound from '@/views/NotFound'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/Home.vue')
    },
    // WOMENS
    {
      path: '/women/:womenName',
      name: 'women',
      props: { womenName: '' },
      component: () => import('../views/women/Women.vue')
    },
    // THE PROJECT
    {
      path: '/project',
      name: 'project',
      component: () => import('../views/Project.vue')
    },
    // GOING FURTHER
    {
      path: '/going-further',
      name: 'further',
      component: () => import('../views/GoingFurther.vue')
    },
    // CONTACT
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/Contact.vue')
    },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})
