<template>
  <div id="app" :class="{'mobile' : isMobile}">
    <NavBar :isMobile="isMobile"></NavBar>
<!--    <transition name="slide" mode="out-in">-->
      <router-view/>
<!--    </transition>-->
  </div>
</template>
<script>
const NavBar = () => import('@/components/NavBar.vue')

export default {
  name: 'App',

  data () {
    return {
      isMobile: false,
    }
  },

  components: {
    NavBar,
  },

  methods: {

  },

  mounted () {
    if ( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      this.isMobile = true
    }

  }
}
</script>

<style lang="scss">
  @import './style/app.scss';

  html {
    height: 100%;

    body {
      height: 100%;
    }

    main {
      flex: 1 1 auto;
      padding-top: 50px;
    }
  }

  #app {
    font-family: 'Merriweather', Helvetica, Arial, sans-serif;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .slide-enter-active, .slide-leave-active {
    transition: opacity 500ms, transform 500ms;
  }

  .slide-enter, .slide-leave-to {
    opacity: 0;
  }
</style>
