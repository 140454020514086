import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import VueCarousel from 'vue-carousel'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueRellax from 'vue-rellax'

Vue.use(VueCarousel)
Vue.use(VueRellax)

Vue.config.productionTip = false

new Vue({
  created () {
    AOS.init({
      once: false
      }
    )
  },

  router,
  render: h => h(App),
}).$mount('#app')
